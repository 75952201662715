<template>
  <b-card body-class="p-0" header-tag="header" header-class="card-row">
    <template #header>
      <b-container fluid class="px-2">
        <b-row>
          <b-col cols="1" class="p-0 mr-2">
            <FeedbackVote :message="message" @refresh="refresh"/>
          </b-col>
          <b-col cols="auto" class="p-0 mr-2 clickable-underline" @click.self="expand = !expand">
            {{ message.title }}
          </b-col>
          <b-col cols="auto" class="p-0 ml-auto">
            <FeedbackStatus :message="message"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="auto" class="p-0 mr-2 text-secondary">
            erstellt von {{ message.user.forename }} {{ message.user.surname }} am {{ message.created_at | datetime }}
          </b-col>
          <b-col cols="auto" class="p-0 ml-auto text-secondary">
            verändert am {{ message.updated_at | datetime }}
          </b-col>
        </b-row>
      </b-container>
    </template>
    <b-card-body v-if="expand" class="feedback-expand py-2 px-2">
      <div class="feedback-expand-text p-2 pre-formatted">
        <p class="mb-0">{{ message.message }}</p>
      </div>
      <div v-if="edit" class="w-25 ml-auto mt-2">
        <b-select v-model="statusSelect.selected" :options="statusSelect.options" size="sm" @change="selectStatus"/>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {putRequest} from "@/modules/requests";
import FeedbackStatus from "@/components/feedback/utility/FeedbackStatus";
import FeedbackVote from "@/components/feedback/utility/FeedbackVote";

export default {
  name: "Row",
  components: {
    FeedbackVote,
    FeedbackStatus
  },
  props: ['message', 'edit'],
  data() {
    return {
      expand: false,
      statusSelect: {
        selected: this.message.status,
        options: [
          {value: 'opened', text: 'Offen'},
          {value: 'accepted', text: 'Akzeptiert'},
          {value: 'rejected', text: 'Abgelehnt'},
          {value: 'development', text: 'In Bearbeitung'},
          {value: 'closed', text: 'Geschlossen'},
        ]
      }
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    async selectStatus() {
      let data = {status: this.statusSelect.selected}
      await putRequest('feedback/status/' + this.message.id, data, this, null)
          .then(() => {
            this.$emit('refresh')
          })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/Feedback.scss';
</style>
