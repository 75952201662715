<template>
  <div>
    <b-container fluid class="px-0 mt-3">
      <b-row>
        <b-col cols="2"/>
        <b-col cols="8">
          <div>
            <b-button variant="primary" size="sm" @click="showModal">Neues Feedback hinzufügen</b-button>
          </div>
          <div v-for="feedbackMessage in feedbackMessages" :key="feedbackMessage.id">
            <Row :message="feedbackMessage" :edit="$route.meta.admin" class="mt-2" @refresh="getFeedbackMessages"/>
          </div>
        </b-col>
        <b-col cols="2"/>
      </b-row>
    </b-container>
    <ScrollToTop/>
    <FeedbackModal/>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions} from "vuex";
import {getRequest} from "@/modules/requests";
import Row from "@/components/feedback/Row";
import ScrollToTop from "@/components/utility/ScrollToTop";
import FeedbackModal from "@/components/feedback/FeedbackModal.vue";

export default {
  name: "Feedback",
  components: {
    FeedbackModal,
    ScrollToTop,
    Row
  },
  data() {
    return {
      feedbackMessages: null
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    showModal() {
      this.$bvModal.show('feedbackModal')
    },
    async getFeedbackMessages() {
      this.setLoadingState(true)
      await getRequest('feedback/all', null, this)
          .then((response) => {
            this.feedbackMessages = _.sortBy(response.data, function (element) {
              let rank = {
                "opened": 1,
                "accepted": 2,
                "development": 3,
                "rejected": 4
              };
              return rank[element.status];
            });
          })
      this.setLoadingState(false)
    }
  },
  async created() {
    await this.getFeedbackMessages()
  }
}
</script>

<style lang="scss" scoped>
</style>
