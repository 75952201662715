<template>
  <div v-if="message.voted">
    <b-icon-arrow-up-circle-fill variant="primary" class="clickable" @click="vote"/>
    <span class="ml-1 text-primary">{{ message.votes }}</span>
  </div>
  <div v-else>
    <b-icon-arrow-up-circle-fill class="clickable" @click="vote"/>
    <span class="ml-1">{{ message.votes }}</span>
  </div>
</template>

<script>
import {putRequest} from "@/modules/requests";

export default {
  name: "FeedbackVote",
  props: ['message'],
  methods: {
    async vote() {
      await putRequest('feedback/vote/' + this.message.id, null, this, null)
          .then(() => {
            this.$emit('refresh')
          })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
