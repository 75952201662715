<template>
  <b-modal id="feedbackModal" :title="'Neues Feedback hinzufügen'" size="lg" @ok="addFeedback">
    <b-form @submit.stop.prevent="">
      <b-form-group :invalid-feedback="invalidFeedback.title">
        <label>Titel:</label>
        <b-form-input :state="state.title" v-model="form.title" class="w-50"/>
      </b-form-group>
      <b-form-group :invalid-feedback="invalidFeedback.message">
        <label>Text:</label>
        <b-form-textarea :state="state.message" v-model="form.message" rows="12"/>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import {putRequest} from "@/modules/requests";

export default {
  name: "FeedbackModal",
  data() {
    return {
      invalidFeedback: {
        title: '',
        message: '',
      },
      state: {
        title: null,
        message: null,
      },
      form: {
        title: "",
        message: ""
      }
    }
  },
  methods: {
    async addFeedback() {
      await putRequest('feedback/add', this.form, this, 'Neues Feedback wurde erfolgreich erstellt.', 'Neues Feedback konnte nicht erstellt werden.')
          .then(() => {
            this.getFeedbackMessages()
            this.form.title = ""
            this.form.message = ""
          })
          .catch(error => {
            let errors = error.response.data.errors
            for (const element in this.invalidFeedback) {
              this.invalidFeedback[element] = ''
            }
            for (const element in this.state) {
              this.state[element] = null
            }
            for (const element in errors) {
              this.invalidFeedback[element] = errors[element][0]
              this.state[element] = false
            }
          })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
