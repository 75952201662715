<template>
  <div>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <SubHeader class="mb-2">
          <b-row class="mt-2 mb-1">
            <b-col cols="3">
              <b-form-input v-model="search" debounce="500" size="sm" placeholder="Suche nach Nutzernamen..."/>
            </b-col>
          </b-row>
        </SubHeader>
        <UserTable v-if="usersFiltered" :tableItems="usersFiltered" @edit-user="edit" @refresh="refresh"/>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <ScrollToTop/>
    <UserModal :data="data" @refresh="refresh"/>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import {mapActions} from "vuex";
import UserModal from "@/components/superadmin/UserModal.vue";
import SubHeader from "@/components/structure/SubHeader.vue";
import ScrollToTop from "@/components/utility/ScrollToTop.vue";
import UserTable from "@/components/superadmin/UserTable.vue";

export default {
    name: "UserManagement",
    components: {
      SubHeader,
      UserModal,
      ScrollToTop,
      UserTable
    },
    data() {
      return {
        search: '',
        users: null,
        data: null
      }
    },
    methods: {
      ...mapActions(['setLoadingState']),
      edit(data) {
        this.data = data
        setTimeout(() => {
            this.$bvModal.show('userModal');
        }, 100)
      },
      async getUsers() {
        await axios.get(process.env.VUE_APP_API_USERS_LDAP_ALL)
            .then((response) => {
                this.users = response.data
            })
      },
      async refresh() {
        this.setLoadingState(true)
        await this.getUsers()
        this.setLoadingState(false)
      }
    },
    computed: {
      usersFiltered() {
        return _.filter(this.users, (entry) => {
          return entry.username.toLowerCase().includes(this.search.toLowerCase())
        })
      }
    },
    created() {
        this.refresh()
    }
}
</script>

<style lang="scss" scoped>
</style>
