<template>
  <b-modal id="userModal" title="User ändern" size="xl" @ok="ok" @hidden="reset" @show="onShow">
    <b-form @submit.stop.prevent="">
      <b-form-row>
        <b-col cols="4">
          <label>Vorname:</label>
          <b-form-input v-model="forename" trim/>
        </b-col>
        <b-col cols="4">
          <label>Nachname:</label>
          <b-form-input v-model="surname" trim/>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col cols="4">
          <label>E-Mail:</label>
          <b-form-input v-model="email" trim/>
        </b-col>
        <b-col cols="4">
          <label>Position:</label>
          <b-form-input v-model="position" trim/>
        </b-col>
        <b-col cols="4">
          <label>Standort:</label>
          <b-form-select v-model="site_id" :options="siteList">
            <template #first>
              <b-form-select-option :value="null" disabled>-- Standort auswählen --</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-form-row>
    </b-form>
    <template #modal-footer="{ ok, cancel }">
      <b-button :variant="variantDanger" @click="cancel()">Abbrechen</b-button>
      <b-button :variant="variantSuccess" @click="ok">
        <b-spinner v-show="loadingOkButton" small/>
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import {mapGetters} from "vuex";
import {toastDanger, toastSuccess} from "@/modules/status";

export default {
  name: "UserModal",
  props: ['data'],
  data() {
    return {
      id: null,
      site_id: null,
      forename: '',
      surname: '',
      email: '',
      position: '',
      loadingOkButton: false
    }
  },
  methods: {
    reset() {
      this.id = null
      this.site_id = null
      this.forename = ''
      this.surname = ''
      this.email = ''
      this.position = ''
    },
    refresh() {
      this.$emit('refresh');
    },
    async ok() {
      await this.updateRow();
      this.reset();
    },
    async onShow() {
      this.id = this.data.id
      this.site_id = this.data.site_id
      this.forename = this.data.forename
      this.surname = this.data.surname
      this.email = this.data.email
      this.position = this.data.position
    },
    async updateRow() {
      let data = {
        site_id: this.site_id,
        forename: this.forename,
        surname: this.surname,
        email: this.email,
        position: this.position
      }

      await axios.put(process.env.VUE_APP_API_USERS_LDAP_UPDATE + '/' + this.id, data)
          .then(() => {
              toastSuccess(this, 'Der Eintrag wurde erfolgreich aktualisiert.')
              this.loadingOkButton = false
              this.refresh()
              this.$bvModal.hide('userModal')
          })
          .catch(() => {
              toastDanger(this, 'Der Eintrag konnte nicht aktualisiert werden.')
              this.loadingOkButton = false
          })
    }
  },
  computed: {
    ...mapGetters(['sites']),
    siteList() {
      let siteList = []
        _.forEach(this.sites, (site) => {
            siteList.push({value: site.id, text: site.displayname})
        })
        return siteList
    },
    ...mapGetters(['userColorAccessibility']),
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
