<template>
  <span>
    <b-badge v-if="message.status === 'opened'" variant="primary">Offen</b-badge>
    <b-badge v-if="message.status === 'accepted'" :variant="variantSuccess">Akzeptiert</b-badge>
    <b-badge v-if="message.status === 'rejected'" :variant="variantDanger">Abgelehnt</b-badge>
    <b-badge v-if="message.status === 'development'" :variant="variantWarning">In Bearbeitung</b-badge>
    <b-badge v-if="message.status === 'closed'" variant="secondary">Geschlossen</b-badge>
  </span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "FeedbackStatus",
  props: ['message'],
  computed: {
    ...mapGetters(['userColorAccessibility']),
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    },
    variantWarning() {
      if(this.userColorAccessibility === 'red-green') { return 'warning-deuteranopia' }
      return 'warning'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
